<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-08 08:49:13
 * @ Description: Fixer's job applicant detail page in the user's job dashboard section of the app.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
        >
            <!-- Heading & Back Button -->
            <v-col
                cols="12"
            >
                <v-btn
                    icon
                    @click="back()"
                    class="mb-1"
                >
                    <v-icon
                        size="24"
                        color="#2E567A"
                        style="opacity: 0.65;"
                    >
                        {{ mdiArrowLeftThinCircleOutline }}
                    </v-icon>
                </v-btn>
                <span
                    class="os-22-sb"
                >
                    Job:
                </span>
                <span
                    class="os-22-r"
                >
                    {{ selectedJob.job_name }}
                </span>
                <span
                    class="os-14-r"
                    style="cursor: pointer;"
                    @click="$router.push(`/job/${$route.params.job_id}/view`)"
                >
                    (Click to view job)
                </span>
            </v-col>
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-divider />
            </v-col>
            <!-- Heading & Back Button -->

            <!-- Applicant Detail -->
            <v-col
                cols="6"
                class="pb-7"
                style="max-height: calc(100vh - 290px); overflow-y: auto;"
            >
                <v-card
                    class="rounded-lg"
                >
                    <v-card-text
                        style="color: black;"
                    >
                        <v-row
                            justify="center"
                        >
                            <v-col
                                cols="8"
                                :style="selectedJob.applicant_declined ? 'opacity: 0.3;' : ''"
                            >
                                <v-row>
                                    <v-col
                                        class="flex-shrink-1 flex-grow-0"
                                    >
                                        <v-avatar
                                            height="46px"
                                            width="46px"
                                        >
                                            <v-img
                                                :src="selectedJob.applicant_avatar ? selectedJob.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                                contain
                                            />
                                        </v-avatar>
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0"
                                    >
                                        <div
                                            style="display: flex; align-items: end;"
                                            class="os-15-sb"
                                        >
                                            <div style="max-width: calc(100% - 24px - 128px); display: inline-block;" class="text-truncate">
                                                {{ selectedJob.applicant_first_name }} {{ selectedJob.applicant_last_name }}
                                            </div>
                                            <div @click="goToIndividualProfile(selectedJob.applicant_id)" class="os-13-r ml-1" style="cursor: pointer; display: inline-block;">(Click to view profile)</div>
                                        </div>
                                        <div
                                            class="os-13-r"
                                            style="color: #2E567A; opacity: 0.65;"
                                        >
                                            Application: {{ selectedJob.job_name }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="4"
                                class="os-13-r text-right"
                                :style="selectedJob.applicant_declined ? 'opacity: 0.3;' : ''"
                            >
                                <div
                                    class="os-15-sb"
                                >
                                    Applied On:
                                </div>
                                <div
                                    class="os-13-r mt-1"
                                    style="color: #2E567A;"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1 ml-n1"
                                    >
                                        {{ mdiCalendar }}
                                    </v-icon>
                                    {{ selectedJob.created_date }}
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-15-sb"
                                >
                                    Introduction:
                                </div>
                                <general-tip-tap-text-editor-component
                                    v-if="editMode"
                                    class="pb-3"
                                    :text="selectedJob.applicant_description"
                                    :limitHeight="false"
                                    @textChanged="descriptionChanged"
                                    :placeholder="'This is your cover letter. Introduce yourself and briefly describe why you would be the best candidate for the position. (Attach your CV - don’t type it here).'"
                                />
                                <div
                                    v-else
                                    class="os-12-r"
                                    v-html="selectedJob.applicant_description"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-right pt-0"
                                v-if="$store.state.user.userData.id === selectedJob.applicant_id && selectedJob.job_stage_id === 2"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    v-if="!editMode"
                                    @click="editMode = true"
                                >
                                    Edit Introduction
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    v-else
                                    @click="saveIntroduction"
                                    :disabled="!selectedJob.applicant_description"
                                >
                                    Save Introduction
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    v-if="editMode"
                                    @click="editMode = false"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>

                            <!-- Documents -->
                            <v-col
                                cols="12"
                                class="os-15-sb"
                            >
                                <v-icon
                                    color="black"
                                    size="20"
                                    class="mr-1"
                                >
                                    {{ mdiCloudDownloadOutline }}
                                </v-icon>
                                Document(s)
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="applicantDocuments"
                                    :canEditDocuments="canEditDocuments"
                                    :canDeleteDocuments="canDeleteDocuments"
                                    :fileType="'other'"
                                    :documentCheckboxes="showDocumentCheckboxes"
                                    @downloadDocument="downloadDocument"
                                    @toggleSelectedDocument="toggleSelectedDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                            <!-- Documents -->

                            <!-- Milestones -->
                            <v-col
                                cols="12"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="canEditPaymentMilestones"
                                    :canPayPaymentMilestones="canPayPaymentMilestones"
                                    :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                    :editMode="canEditPaymentMilestones"
                                    :paymentMilestones="paymentMilestones"
                                    :validatePaymentMilestoneDates="validatePaymentMilestoneDates"
                                    @savePaymentMilestones="confirmSavePaymentMilestones"
                                    @removePaymentMilestone="removePaymentMilestone"
                                    @disablePaymentMilestonesSaveButton="disablePaymentMilestonesSaveButton"
                                    @getPaymentMilestonesInMemory="getPaymentMilestonesInMemory"
                                    @refreshPaymentMilestones="getJobPaymentMilestones"
                                />
                            </v-col>
                            <!-- Milestones -->

                            <!-- Action Buttons -->
                            <v-col
                                cols="12"
                                class="pb-0"
                                v-if="$store.state.user.userData.id === selectedJob.owner_id"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="7"
                                class="py-8"
                                v-if="$store.state.user.userData.id === selectedJob.owner_id"
                            >
                                <span
                                    v-if="!selectedJob.applicant_declined"
                                >
                                    <v-row>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleScheduleInterview"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Interview
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="toggleApplicantFavourite"
                                            >
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-if="selectedJob.applicant_favourite"
                                                >
                                                    {{ mdiHeart }}
                                                </v-icon>
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-else
                                                >
                                                    {{ mdiHeartOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Favourite
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmApplicantAssign"
                                                :disabled="disableAssignApplicantButton"
                                            >
                                                <v-icon
                                                    color="#069B34"
                                                    size="20"
                                                >
                                                    {{ mdiCheckCircleOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Assign
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; "
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleApplicantDecline"
                                            >
                                                <v-icon
                                                    color="#E80620"
                                                    size="20"
                                                >
                                                    {{ mdiClose }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Decline
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span
                                    v-else
                                >
                                    <v-row>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Interview
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-if="selectedJob.applicant_favourite"
                                                >
                                                    {{ mdiHeart }}
                                                </v-icon>
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-else
                                                >
                                                    {{ mdiHeartOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Favourite
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#069B34"
                                                    size="20"
                                                >
                                                    {{ mdiCheckCircleOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Assign
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleApplicantDecline"
                                            >
                                                <v-icon
                                                    color="#44D7B6"
                                                    size="20"
                                                >
                                                    {{ mdiPlus }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Restore
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                            <!-- Action Buttons -->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- Applicant Detail -->

            <!-- Messages -->
            <v-col
                cols="6"
                class="pb-0"
                style="max-height: calc(100vh - 290px); overflow-y: auto;"
            >
                <general-work-messaging-component
                    :selectedItem="selectedJob"
                    @resetUnreadMessageCount="resetUnreadMessageCount"
                />
            </v-col>
            <!-- Messages -->
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-if="$vuetify.breakpoint.smAndDown"
        >
            <!-- Heading & Back Button -->
            <v-col
                cols="12"
            >
                <v-btn
                    icon
                    @click="back()"
                    class="mb-1"
                >
                    <v-icon
                        size="24"
                        color="#2E567A"
                        style="opacity: 0.65;"
                    >
                        {{ mdiArrowLeftThinCircleOutline }}
                    </v-icon>
                </v-btn>
                <span
                    class="os-22-sb"
                >
                    Job:
                </span>
                <span
                    class="os-22-r"
                >
                    {{ selectedJob.job_name }}
                </span>
                <br v-if="$vuetify.breakpoint.xs" />
                <span
                    class="os-14-r"
                    style="cursor: pointer;"
                    @click="$router.push(`/job/${$route.params.job_id}/view`)"
                >
                    (Click to view job)
                </span>
            </v-col>
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-divider />
            </v-col>
            <!-- Heading & Back Button -->

            <!-- Applicant Detail -->
            <v-col
                cols="12"
            >
                <v-card
                    class="rounded-lg"
                >
                    <v-card-text
                        style="color: black;"
                    >
                        <v-row
                            justify="center"
                        >
                            <v-col
                                class="flex-shrink-1 flex-grow-0"
                                :style="selectedJob.applicant_declined ? 'opacity: 0.3;' : ''"
                            >
                                <v-avatar
                                    height="46px"
                                    width="46px"
                                >
                                    <v-img
                                        :src="selectedJob.applicant_avatar ? selectedJob.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                class="flex-grow-1 flex-shrink-0 text-right text-sm-left"
                                :style="selectedJob.applicant_declined ? 'opacity: 0.3;' : ''"
                            >
                                <div
                                    class="os-15-sb"
                                >
                                    {{ selectedJob.applicant_first_name }} {{ selectedJob.applicant_last_name }}
                                </div>
                                <div
                                    class="os-13-r"
                                    style="color: #2E567A; opacity: 0.65;"
                                >
                                    Application: {{ selectedJob.job_name }}
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                class="os-13-r text-right pt-0 pt-sm-3"
                                :style="selectedJob.applicant_declined ? 'opacity: 0.3;' : ''"
                            >
                                <span
                                    class="os-15-sb"
                                >
                                    Applied On: 
                                </span>
                                <br v-if="$vuetify.breakpoint.sm" />
                                <span
                                    class="os-13-r mt-1"
                                    style="color: #2E567A;"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1 ml-n1"
                                        v-if="$vuetify.breakpoint.sm"
                                    >
                                        {{ mdiCalendar }}
                                    </v-icon>
                                    {{ selectedJob.created_date }}
                                </span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-15-sb"
                                >
                                    Introduction:
                                </div>
                                <general-tip-tap-text-editor-component
                                    v-if="editMode"
                                    class="pb-3"
                                    :text="selectedJob.applicant_description"
                                    :limitHeight="false"
                                    @textChanged="descriptionChanged"
                                    :placeholder="'This is your cover letter. Introduce yourself and briefly describe why you would be the best candidate for the position. (Attach your CV - don’t type it here).'"
                                />
                                <div
                                    v-else
                                    class="os-12-r"
                                    v-html="selectedJob.applicant_description"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-right pt-0"
                                v-if="$store.state.user.userData.id === selectedJob.applicant_id"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    v-if="!editMode"
                                    @click="editMode = true"
                                >
                                    Edit <span v-if="!$store.state.general.customBreakpoints.xxs">Introduction</span>
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    v-else
                                    @click="saveIntroduction"
                                    :disabled="!selectedJob.applicant_description"
                                >
                                    Save <span v-if="!$store.state.general.customBreakpoints.xxs">Introduction</span>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    v-if="editMode"
                                    @click="editMode = false"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>

                            <!-- Documents -->
                            <v-col
                                cols="12"
                                class="os-15-sb"
                            >
                                <v-icon
                                    color="black"
                                    size="20"
                                    class="mr-1"
                                >
                                    {{ mdiCloudDownloadOutline }}
                                </v-icon>
                                Document(s)
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="applicantDocuments"
                                    :canEditDocuments="canEditDocuments"
                                    :canDeleteDocuments="canDeleteDocuments"
                                    :fileType="'other'"
                                    :documentCheckboxes="showDocumentCheckboxes"
                                    @downloadDocument="downloadDocument"
                                    @toggleSelectedDocument="toggleSelectedDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                            <!-- Documents -->

                            <!-- Milestones -->
                            <v-col
                                cols="12"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="canEditPaymentMilestones"
                                    :canPayPaymentMilestones="canPayPaymentMilestones"
                                    :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                    :editMode="canEditPaymentMilestones"
                                    :paymentMilestones="paymentMilestones"
                                    :validatePaymentMilestoneDates="validatePaymentMilestoneDates"
                                    @savePaymentMilestones="confirmSavePaymentMilestones"
                                    @removePaymentMilestone="removePaymentMilestone"
                                    @disablePaymentMilestonesSaveButton="disablePaymentMilestonesSaveButton"
                                    @getPaymentMilestonesInMemory="getPaymentMilestonesInMemory"
                                    @refreshPaymentMilestones="getJobPaymentMilestones"
                                />
                            </v-col>
                            <!-- Milestones -->

                            <!-- Action Buttons -->
                            <v-col
                                cols="12"
                                class="pb-0"
                                v-if="$store.state.user.userData.id === selectedJob.owner_id"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="7"
                                class="py-8"
                                v-if="$store.state.user.userData.id === selectedJob.owner_id"
                            >
                                <span
                                    v-if="!selectedJob.applicant_declined"
                                >
                                    <v-row>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleScheduleInterview"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Interview
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="toggleApplicantFavourite"
                                            >
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-if="selectedJob.applicant_favourite"
                                                >
                                                    {{ mdiHeart }}
                                                </v-icon>
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-else
                                                >
                                                    {{ mdiHeartOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Favourite
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmApplicantAssign"
                                                :disabled="disableAssignApplicantButton"
                                            >
                                                <v-icon
                                                    color="#069B34"
                                                    size="20"
                                                >
                                                    {{ mdiCheckCircleOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Assign
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; "
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleApplicantDecline"
                                            >
                                                <v-icon
                                                    color="#E80620"
                                                    size="20"
                                                >
                                                    {{ mdiClose }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Decline
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span
                                    v-else
                                >
                                    <v-row>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Interview
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-if="selectedJob.applicant_favourite"
                                                >
                                                    {{ mdiHeart }}
                                                </v-icon>
                                                <v-icon
                                                    color="#FFA858"
                                                    size="20"
                                                    v-else
                                                >
                                                    {{ mdiHeartOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Favourite
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0; opacity: 0.3;"
                                        >
                                            <v-btn
                                                icon
                                                disabled
                                            >
                                                <v-icon
                                                    color="#069B34"
                                                    size="20"
                                                >
                                                    {{ mdiCheckCircleOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Assign
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="text-center pa-0"
                                            style="line-height: 0;"
                                        >
                                            <v-btn
                                                icon
                                                @click="confirmToggleApplicantDecline"
                                            >
                                                <v-icon
                                                    color="#44D7B6"
                                                    size="20"
                                                >
                                                    {{ mdiPlus }}
                                                </v-icon>
                                            </v-btn>
                                            <br />
                                            <span
                                                class="os-12-sb"
                                                style="color: #2E567A;"
                                            >
                                                Restore
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                            <!-- Action Buttons -->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- Applicant Detail -->

            <!-- Messages -->
            <v-col
                cols="12"
                class="pb-9"
            >
                <general-work-messaging-component
                    :selectedItem="selectedJob"
                    @resetUnreadMessageCount="resetUnreadMessageCount"
                />
            </v-col>
            <!-- Messages -->
        </v-row>
        <!-- Mobile -->

        <!-- Confirm Decline Applicant Overlay -->
        <work-general-applicant-confirm-decline-toggle-overlay-component
            :showDeclineToggleOverlayComponent="showDeclineToggleOverlayComponent"
            :applicantToToggle="selectedJob"
            @toggleDeclineToggleOverlayComponent="toggleDeclineToggleOverlayComponent"
        />
        <!-- Confirm Decline Applicant Overlay -->

        <!-- Schedule Interview Overlay -->
        <work-general-applicant-schedule-interview-overlay-component
            :showScheduleInterviewOverlayBoolean="showScheduleInterviewOverlayBoolean"
            :applicantToInterview="applicant"
            :applicantToInterviewJob="selectedJob"
            @toggleScheduleInterviewOverlayComponent="toggleScheduleInterviewOverlayComponent"
        />
        <!-- Schedule Interview Overlay -->

        <!-- Confirm Assign Applicant Overlay -->
        <work-general-applicant-confirm-assign-overlay-component
            :showAssignConfirmationOverlayComponent="showAssignConfirmationOverlayComponent"
            :applicantToAssign="applicant"
            :applicantToAssignJob="selectedJob"
            @toggleAssignConfirmationOverlayComponent="toggleAssignConfirmationOverlayComponent"
        />
        <!-- Confirm Assign Applicant Overlay -->

        <!-- Confirm Save Milestones Overlay -->
        <work-general-payment-milestones-confirm-save-overlay-component
            :showSaveMilestonesConfirmationOverlayBoolean="showSaveMilestonesConfirmationOverlayBoolean"
            @showSaveMilestonesConfirmationOverlay="showSaveMilestonesConfirmationOverlay"
        />
        <!-- Confirm Save Milestones Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </div>
</template>
<script>
    import GeneralWorkPaymentMilestonesComponent from '@/components/general/work/GeneralWorkPaymentMilestonesComponent.vue'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import { mdiCalendar, mdiHeartOutline, mdiHeart, mdiClose, mdiCheckCircleOutline, mdiCloudDownloadOutline, mdiArrowLeftThinCircleOutline, mdiPlus } from '@mdi/js'
    import GeneralWorkMessagingComponent from '@/components/general/work/GeneralWorkMessagingComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import { GeneralInvoiceController, WorkJobApplicationChatController, WorkJobApplicationDocumentController, WorkJobApplicationDetailController, WorkJobApplicationMilestoneController, WorkJobApplicationStatusController, GeneralProfileApplicantController, GeneralProfileDocumentController, WorkJobApplicationInterviewController } from '@/logic/controller/index.controller.js'
    import WorkGeneralApplicantConfirmDeclineToggleOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmDeclineToggleOverlayComponent.vue'
    import WorkGeneralApplicantScheduleInterviewOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantScheduleInterviewOverlayComponent.vue'
    import WorkGeneralApplicantConfirmAssignOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmAssignOverlayComponent.vue'
    import WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent from '@/overlays/work/general/WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'WorkJobDetailGeneralApplicantPage',

        components: {
            GeneralWorkPaymentMilestonesComponent,
            GeneralTipTapTextEditorComponent,
            GeneralWorkMessagingComponent,
            GeneralDocumentComponent,
            WorkGeneralApplicantConfirmDeclineToggleOverlayComponent,
            WorkGeneralApplicantScheduleInterviewOverlayComponent,
            WorkGeneralApplicantConfirmAssignOverlayComponent,
            WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent,
            PaymentOverlayComponent
        },

        watch: {
            '$route.params.job_id': {
                async handler (value) {
                    await this.getJobDetail()
                    await this.getJobPaymentMilestones()
                }
            },

            '$route.params.applicant_id': {
                async handler (value) {
                    await this.getJobDetail()
                    await this.getJobPaymentMilestones()
                }
            }
        },

        computed: {
            canEditPaymentMilestones () {
                if (this.selectedJob.applicant_declined) return false
                else if ((this.selectedJob.job_stage_id === 2) && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                else return false
            },

            canPayPaymentMilestones () {
                if (this.selectedJob.job_stage_id === 3 && this.selectedJob.owner_id === this.$store.state.user.userData.id) return true
                else return false
            },

            canRefreshPaymentMilestones () {
                if (this.selectedJob.applicant_id === this.$store.state.user.userData.id) return true
                else return false
            },

            canEditDocuments () {
                if (this.selectedJob.job_stage_id === 2 && this.selectedJob.applicant_id === this.$store.state.user.userData.id) return true
                else return false
            },

            canDeleteDocuments () {
                return false
            },

            showDocumentCheckboxes () {
                if (this.selectedJob.job_stage_id === 2 && this.selectedJob.applicant_id === this.$store.state.user.userData.id) return true
                else return false
            }
        },

        data () {
            return {
                mdiCalendar,
                mdiHeartOutline,
                mdiHeart,
                mdiClose,
                mdiCheckCircleOutline,
                mdiCloudDownloadOutline,
                mdiArrowLeftThinCircleOutline,
                mdiPlus,
                selectedJob: {},
                editMode: false,
                workJobApplicationChatController: null,
                workJobApplicationDocumentController: null,
                workJobApplicationDetailController: null,
                workJobApplicationMilestoneController: null,
                workJobApplicationInterviewController: null,
                generalProfileApplicantController: null,
                generalProfileDocumentController: null,
                generalInvoiceController: null,
                paymentMilestones: [],
                applicantDocuments: [],
                workJobApplicationStatusController: null,
                showDeclineToggleOverlayComponent: false,
                showScheduleInterviewOverlayBoolean: false,
                applicant: {},
                showAssignConfirmationOverlayComponent: false,
                validatePaymentMilestoneDates: true,
                disableAssignApplicantButton: false,
                paymentMilestonesInMemory: [],
                showSaveMilestonesConfirmationOverlayBoolean: false,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false
            }
        },

        beforeMount () {
            this.workJobApplicationChatController = new WorkJobApplicationChatController()
            this.workJobApplicationDocumentController = new WorkJobApplicationDocumentController()
            this.workJobApplicationDetailController = new WorkJobApplicationDetailController()
            this.workJobApplicationMilestoneController = new WorkJobApplicationMilestoneController()
            this.workJobApplicationStatusController = new WorkJobApplicationStatusController()
            this.generalProfileApplicantController = new GeneralProfileApplicantController()
            this.generalProfileDocumentController = new GeneralProfileDocumentController()
            this.workJobApplicationInterviewController = new WorkJobApplicationInterviewController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            await this.getJobDetail()
            await this.getapplicantDocuments()
            await this.getJobPaymentMilestones()
        },

        methods: {
            confirmToggleScheduleInterview () {
                this.toggleScheduleInterviewOverlayComponent(true)
            },

            toggleScheduleInterviewOverlayComponent (value, confirmInterview, interview) {
                this.showScheduleInterviewOverlayBoolean = value

                if (confirmInterview) this.scheduleApplicantInterview(interview)
            },

            async scheduleApplicantInterview (interview) {
                try {
                    const dayjs = require('dayjs')

                    interview.start_date_time = dayjs(interview.start_date_time).format()
                    interview.end_date_time = dayjs(interview.end_date_time).format()

                    await this.workJobApplicationInterviewController.create(interview)

                    this.$store.commit('setSnackbar', {
                        text: 'Interview scheduled successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmApplicantAssign () {
                this.toggleAssignConfirmationOverlayComponent(true)
            },

            toggleAssignConfirmationOverlayComponent (value, confirmApplication) {
                this.showAssignConfirmationOverlayComponent = value

                if (confirmApplication) this.assignApplicant(false)
            },

            async assignApplicant (afterPayment) {
                try {
                    if (!afterPayment) await this.savePaymentMilestones(this.paymentMilestonesInMemory)

                    await this.workJobApplicationStatusController.assign({ job_id: this.selectedJob.job_id, applicant_id: this.selectedJob.applicant_id })

                    this.$store.commit('setSnackbar', {
                        text: 'Assigned successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.$router.push('/job/active')
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    }
                    else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmAssign, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmAssign) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.assignApplicant(true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobDetail () {
                try {
                    this.selectedJob = await this.workJobApplicationDetailController.retrieve(this.$route.params.job_id, this.$route.params.applicant_id)

                    this.applicant = {
                        applicant_id: this.selectedJob.applicant_id,
                        applicant_avatar: this.selectedJob.applicant_avatar,
                        applicant_full_name: this.selectedJob.applicant_first_name + ' ' + this.selectedJob.applicant_last_name
                    }

                    if (this.selectedJob.created_date) {
                        const removeTime = this.selectedJob.created_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.selectedJob.created_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getapplicantDocuments() {
                try {
                    this.applicantDocuments = await this.workJobApplicationDocumentController.retrieve(this.$route.params.job_id, this.$route.params.applicant_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobPaymentMilestones () {
                try {
                    this.paymentMilestones = await this.workJobApplicationMilestoneController.retrieve(this.$route.params.job_id, this.$route.params.applicant_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            back () {
                if (this.$store.state.user.userContext === 'company') this.$router.push('/job/applicants')
                else if (this.$store.state.user.userContext === 'individual') this.$router.push('/job/applications')
            },

            async saveIntroduction () {
                try {
                    if (this.selectedJob.applicant_description === '<p></p>') this.selectedJob.applicant_description = null

                    await this.workJobApplicationDetailController.upsert(this.selectedJob)

                    this.$store.commit('setSnackbar', {
                        text: 'Saved successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.editMode = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            descriptionChanged (text) {
                this.selectedJob.applicant_description = text
                if (this.selectedJob.applicant_description === '<p></p>') this.selectedJob.applicant_description = null
            },

            confirmSavePaymentMilestones () {
                this.showSaveMilestonesConfirmationOverlay(true)
            },

            showSaveMilestonesConfirmationOverlay (value, confirmSave) {
                this.showSaveMilestonesConfirmationOverlayBoolean = value

                if (confirmSave) this.savePaymentMilestones(this.paymentMilestonesInMemory)
            },

            async savePaymentMilestones (milestones) {
                try {
                    if (milestones && milestones.length > 0) {
                        let milestoneID = null

                        if (this.paymentMilestones.length > 0) {
                            const milestoneIndex = this.paymentMilestones.findIndex(itemToFind => itemToFind.milestone_id)
                            if (milestoneIndex > -1) milestoneID = this.paymentMilestones[milestoneIndex].milestone_id
                        }

                        for (let index = 0; index < milestones.length; index++) {
                            const milestone = milestones[index]

                            milestone.job_id = this.selectedJob.job_id
                            milestone.owner_id = this.selectedJob.owner_id
                            milestone.applicant_id = this.selectedJob.applicant_id
                            milestone.milestone_id = milestoneID

                            delete milestone.milestoneDateMenu
                            delete milestone.validMilestoneForm

                            const response = await this.workJobApplicationMilestoneController.upsert(milestone)
                            milestoneID = response[0].milestone_id
                        }

                        this.paymentMilestonesInMemory = []
                        this.getJobPaymentMilestones()
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async removePaymentMilestone (milestone) {
                try {
                    milestone.job_id = this.selectedJob.job_id
                    milestone.owner_id = this.selectedJob.owner_id
                    milestone.applicant_id = this.selectedJob.applicant_id
                    delete milestone.milestoneDateMenu
                    delete milestone.validMilestoneForm

                    this.paymentMilestones = await this.workJobApplicationMilestoneController.delete(milestone)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument (documentToDownload) {
                try {
                    const downloadResponse = await this.generalProfileDocumentController.download(documentToDownload)

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async toggleSelectedDocument (document) {
                try {
                    if (document.isApplicationDoc) {
                        this.workJobApplicationDocumentController.create(document, this.$route.params.job_id, this.$route.params.applicant_id)

                        this.$store.commit('setSnackbar', {
                            text: 'Added to application',
                            show: true,
                            color: '#069B34'
                        })
                    } else {
                        this.workJobApplicationDocumentController.delete(document, this.$route.params.job_id, this.$route.params.applicant_id)

                        this.$store.commit('setSnackbar', {
                            text: 'Removed from application',
                            show: true,
                            color: '#069B34'
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async createDocument (document) {
                try {
                    const response = await this.generalProfileDocumentController.create(document)

                    if (response && response.length > 0) {
                        if (this.applicantDocuments && this.applicantDocuments.length > 0) {
                            for (let index = 0; index < response.length; index++) {
                                const responseDocument = response[index]
                                const documentIndex = this.applicantDocuments.findIndex(documentToFind => documentToFind.fullpath === responseDocument.fullpath)
                                if (documentIndex < 0) this.applicantDocuments.push(responseDocument)
                            }
                        } else this.applicantDocuments = response
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmToggleApplicantDecline () {
                this.toggleDeclineToggleOverlayComponent(true)
            },

            toggleDeclineToggleOverlayComponent (value, confirmApplication) {
                this.showDeclineToggleOverlayComponent = value

                if (confirmApplication) this.toggleApplicantDecline()
            },

            async toggleApplicantDecline () {
                try {
                    const response = await this.workJobApplicationStatusController.toggleDecline({ job_id: this.selectedJob.job_id, applicant_id: this.selectedJob.applicant_id })

                    let action
                    if (response[0].applicant_declined) action = 'Declined'
                    else action = 'Restored'

                    this.selectedJob.applicant_declined = response[0].applicant_declined

                    this.$store.commit('setSnackbar', {
                        text: `${action} successfully`,
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async toggleApplicantFavourite () {
                try {
                    const response = await this.generalProfileApplicantController.toggleFavourite({ owner_id: this.selectedJob.owner_id, applicant_id: this.selectedJob.applicant_id })

                    let action
                    if (response.applicant_favourite) action = 'Added to'
                    else action = 'Removed from'

                    this.selectedJob.applicant_favourite = response.applicant_favourite

                    this.$store.commit('setSnackbar', {
                        text: `${action} favourites`,
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            disablePaymentMilestonesSaveButton (value) {
                this.disableAssignApplicantButton = value
            },

            getPaymentMilestonesInMemory (milestones) {
                this.paymentMilestonesInMemory = milestones
            },

            goToIndividualProfile (individualID) {
                this.$router.push(`/profile/individual/${individualID}`)
            },

            resetUnreadMessageCount () {
                try {
                    this.workJobApplicationChatController.resetMessageCount(this.selectedJob)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
